import React, { useState } from 'react';
import { Button, Form, Input, Card, Image, message } from 'antd';
import axios from 'axios';
import { Url } from '../../Http';

const Index = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(Url + '/api/login', {
        username: values.username,
        password: values.password
      });

      if (response.data.success) {
        // Store JWT token in localStorage
        localStorage.setItem('token', response.data.token);

        message.success(response.data.message);

        window.location.href = '/ApiRaltHrAdmin-dashboard'; // Redirect to the dashboard
      } else {
        message.error('Login failed: ' + response.data.message);
      }
    } catch (error) {
      message.error('Login failed: Invalid credentials or server error');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='adminWrapper'>
      <div className='loginSection'>
        <Card>
          <div className='loginHeader'>
            <div className='textWrapper'>
              <h2>RaltHR</h2>
              <span>An Outplacement Agency</span>
            </div>
            {/* <Image
              src="https://jobassist.in/wp-content/uploads/2024/08/cropped-symetrical-business-logo-115x49.png"
              className="img-fluid"
              alt="Not found"
              preview={false}
            /> */}
            {/* <h1>Login</h1> */}
          </div>
          <hr />
          <div className='formWrapper'>
            <Form
              name="basic"
              initialValues={{ username: '', password: '' }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div>
                <label>Username</label>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input autoComplete="new-username" />
                </Form.Item>
              </div>
              <div>
                <label>Password</label>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </div>
              <div className='buttonWrapper'>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Index;
