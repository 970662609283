import React from 'react'
import { Row, Col, Image } from 'antd';
import Service3 from "../Assets/Images/Service3.png";
// import ReactPlayer from 'react-player/lazy';

const ReverseRecruiting = (props) => {
  // let propData = props?.data;

  return (
    <div className='reverseWrapper' id="Reverse">
      <Row className='topSection'>
        <Col md={12} xs={24} className='leftSection'>
          <div>
          <h2 style={{fontWeight:700}}>Reverse Recruiting</h2>
            <p>
              Reverse recruiting is a premium service designed to simplify your job
              search. If you are an executive or busy professional, finding the time to
              look for a job can be overwhelming. Our team of certified reverse
              recruiters, who are experts in the industry and specially trained, take
              care of everything for you.
            </p>
            <p>
              We find the best job opportunities on your behalf, handle applications,
              and network with key decision-makers. With reverse recruiting, you can
              focus on your current role while we manage the job search process and
              work to secure the best job for you.

            </p>
          </div>
        </Col>
        <Col md={12} xs={24} className='RightSection'>
          <div className='imgWrapper'>
            {/* <ReactPlayer url={propData?.videoUrl} className="videoWrapper" /> */}
              <Image src={Service3} alt="Not found" className='img-fluid' preview={false} /> 
          </div>
        </Col>
      </Row>
      <Row className='bottomSection'>
        <div className='listWrapper'>
        <h2 style={{fontWeight:700}}>How does It work for You?</h2>
          <Row>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>Job Matching:</strong> We find and select jobs that
                  match your criteria.</li>
                <li><strong>Resume Optimization:</strong> We tailor your resume
                  to every accepted job.</li>
                <li><strong>Application Handling:</strong> We apply to jobs on your
                  behalf.</li>
                <li><strong>Networking:</strong> We connect with key decisionmakers to get you an interview.</li>
              </ul>
            </Col>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>Interview Preparation:</strong> Our experts help you
                  prepare for interviews and job offers.</li>
                <li><strong>Increased Opportunities:</strong> Our goal is to get
                  you 5x more interviews.</li>
                <li><strong>Personalized Approach:</strong> Jobs are selected
                  based on your preferences.</li>
                <li><strong>Stress-Free Process:</strong> We manage the entire job
                  search for you.</li>
              </ul>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default ReverseRecruiting