import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdOutlineSpaceDashboard, MdOutlineEmail } from "react-icons/md";
import { LiaPagerSolid } from "react-icons/lia";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoBagCheckOutline } from "react-icons/io5";
import { CiMoneyCheck1 } from "react-icons/ci";

const { Sider } = Layout;

const menuItems = [
  {
    key: '/ApiRaltHrAdmin-dashboard',
    label: 'Dashboard',
    icon: <MdOutlineSpaceDashboard />,
    path: '/ApiRaltHrAdmin-dashboard'
  },
  {
    key: '/ApiRaltHrAdmin-landing-page',
    label: 'Landing Page',
    icon: <LiaPagerSolid />,
    path: '/ApiRaltHrAdmin-landing-page'
  },
  {
    key: '/ApiRaltHrAdmin-service',
    label: 'Service',
    icon: <MdOutlineMiscellaneousServices />,
    path: '/ApiRaltHrAdmin-service'
  },
  {
    key: '/ApiRaltHrAdmin-package',
    label: 'Package',
    icon: <IoBagCheckOutline />,
    path: '/ApiRaltHrAdmin-package'
  },
  {
    key: '/ApiRaltHrAdmin-users',
    label: 'Users',
    icon: <UserOutlined />,
    path: '/ApiRaltHrAdmin-users'
  },
  {
    key: '/ApiRaltHrAdmin-payment',
    label: 'Payment',
    icon: <CiMoneyCheck1 />,
    path: '/ApiRaltHrAdmin-payment'
  },
  {
    key: '/ApiRaltHrAdmin-email-templates',
    label: 'Email Templates',
    icon: <MdOutlineEmail />,
    path: '/ApiRaltHrAdmin-email-templates'
  },
];

const AppSider = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [selectedKey, setSelectedKey] = useState(location.pathname); // State to manage selected key

  // Update selected key when the URL changes
  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);


  useEffect(() => {
    const currentPath = location.pathname;

    // Logic to match routes like /ApiRaltHrAdmin-new-service with /ApiRaltHrAdmin-service
    if (currentPath.startsWith('/ApiRaltHrAdmin-service')) {
      setSelectedKey('/ApiRaltHrAdmin-service');
    } else if (currentPath.startsWith('/ApiRaltHrAdmin-package')) {
      setSelectedKey('/ApiRaltHrAdmin-package');
    } else {
      setSelectedKey(currentPath); // For exact match paths
    }
  }, [location.pathname]);

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="headerLogoWrapper">
        <h3>RaltHR</h3>
        <span>An Outplacement Agency</span>
        {/* <Image
          src="https://jobassist.in/wp-content/uploads/2024/08/cropped-symetrical-business-logo-115x49.png"
          alt="Not found"
          className="img-fluid imgHeader"
          style={{ cursor: 'Pointer' }}
          preview={false}
          onClick={() => navigate('/admin-dashboard')}
        /> */}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]} // Set selected item dynamically
        items={menuItems.map(item => ({
          key: item.key,
          icon: item.icon,
          label: item.label,
          onClick: () => navigate(item.path), // Navigate on click
        }))}
      />
    </Sider>
  );
};

export default AppSider;
