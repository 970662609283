import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
// import 'antd/dist/antd.css'; // Make sure to import Ant Design styles

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Initialize countdown to 5 seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1); // Decrease countdown by 1 every second
    }, 1000);

    // Redirect to homepage after 5 seconds
    const redirectTimeout = setTimeout(() => {
      navigate('/'); // Redirect to homepage
    }, 5000);

    return () => {
      clearInterval(timer); // Cleanup interval on component unmount
      clearTimeout(redirectTimeout); // Cleanup timeout on component unmount
    };
  }, [navigate]);

  return (
    <div className="payment-success-container" style={styles.container}>
      <Result
        status="success"
        title="Payment Successful!"
        subTitle={`Thank you for your payment. Please check your email. You will be contacted by the RaltHR admin team soon. You will be redirected to the homepage in ${countdown} seconds.`}
        extra={[
          <Button type="primary" key="console" onClick={() => navigate('/')}>
            Go to Homepage
          </Button>,
        ]}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5', // Light background color
    textAlign: 'center',
  },
};

export default PaymentSuccess;
