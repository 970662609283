import React from 'react'
import { Row, Col } from 'antd';

const Footer = () => {
  return (
    <div className='footerMain'>
      <div className='upperSection'>
        <Row>
          <Col md={12} xs={24} className='headingSection'>
            <h2>RaltHR</h2>
            <p>RaltHR is dedicated to helping professionals land their dream jobs, including customized resume writing, expert reverse recruiting, and personalized career assistance. We empower candidates by matching opportunities with their skills and passions, providing guidance, resources, and unwavering support every step of the way toward long-term career success.</p>
          </Col>

          <Col md={6} xs={24} className='middleSection'>
            <div className='textSection'>
              <h2>Important Links</h2>
              <div className='iconsList'>
                <a href="#banner">Home</a>
                <a href="#about">About</a>
                <a href="#services">What We Do</a>
                <a href="#pricing">Pricing</a>
                <a href="#why-us">Why Us</a>
                <a href="#contact">Contact</a>
              </div>
            </div>
          </Col>

          <Col md={6} xs={24} className='rightSection'>
            <div className='textSection'>
              <h2>About Us</h2>
              <div className='iconsList'>
                <a href="/disclaimer">Disclaimer</a>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/reviews">Refund Policy</a>
                <a href="/terms-and-conditions">Terms and Conditions</a>
                <a href="/faqs">FAQs</a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <hr />
      <div className='lowerSection'>
        <Row>
          <Col md={8}>
            <div>
              <span>Copyright 2024 | All Rights Reserved</span>
            </div>
          </Col>
          <Col md={8}>
            <div>
              <span><b>Company Info:</b> Ralt Infotech Pvt. Ltd.</span>
            </div>
          </Col>
          <Col md={8}>
            <div>
              <span><b>Channel Partner:</b> <a href="https://banksterindia.com" target='_blank' rel="noreferrer">banksterindia.com</a></span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Footer;