import React, { useEffect, useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Table, Layout, theme, Button } from 'antd';
import axios from 'axios';
import { Url } from '../../../../Http';
import { useNavigate } from 'react-router-dom';
const { Content, Footer } = Layout;

const Index = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();
    const [templateList, setTemplateList] = useState([]);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        try {
            const res = await axios.get(Url + '/api/get-all-templates');
            setTemplateList(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const editTemplate = (data) => {
        navigate(`/ApiRaltHrAdmin-edit-template/${data?._id}`)
    }

    // const addNewTemplate = (data) => {
    //     navigate(`/admin-add-template`)
    // }

    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            render: ((record, index) => (
                <>
                    <Button onClick={() => editTemplate(record)}>Edit</Button>
                    {/* <Button onClick={() => addNewTemplate(record)}>Add New</Button> */}
                </>
            ))
        },
    ];


    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className="serviceAdminContent"
                    >
                        <div className="serviceMain">
                            <div className="pageHeader">
                                <h3>Users</h3>
                            </div>
                            <div className="serviceTableWrapper">
                                <Table columns={columns} dataSource={templateList} rowKey="_id" pagination={false} scroll={{ x: 1000 }} />
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    )
}

export default Index;