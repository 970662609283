import React, { useEffect, useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Space, Table, Layout, theme, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Url } from "../../../../Http";
import Swal from 'sweetalert2';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

const { Content, Footer } = Layout;

const AdminPackage = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();  // Correct way to access theme tokens
    const navigate = useNavigate();
    const [packageList, setPackageList] = useState([]);


    const fetchPackage = () => {
        axios.get(Url + '/api/get-packages')
            .then((res) => {
                console.info("Response", res);
                setPackageList(res?.data);
            })
            .catch((err) => {
                console.error("Error", err);
            })
    }
    useEffect(() => {
        fetchPackage()
    }, []);

    // Handle service deletion
    const deletePackage = async (id) => {
        try {
            const res = await Swal.fire({
                title: 'Are you sure you want to remove this Package?',
                icon: 'info',
                showCancelButton: true, // Show the cancel button to allow dismissal
                confirmButtonText: 'Remove',
                cancelButtonText: 'Cancel'
            });

            if (res.isConfirmed) {
                await axios.delete(`${Url}/api/delete-package/${id}`);
                message.success('Package deleted successfully');
                fetchPackage(); // Refresh the list after deletion
            }
        } catch (err) {
            console.error('Failed to delete service:', err);
            message.error('Failed to delete service');
        }
    };

    const editPackage = (id) => {
        navigate(`/ApiRaltHrAdmin-package-edit/${id}`);
    };


    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => editPackage(record._id)}><CiEdit />Edit</Button>
                    <Button onClick={() => deletePackage(record._id)} danger><RiDeleteBin6Line />Delete</Button>
                </Space>
            ),

        },
    ];

    const addNew = () => {
        navigate('/ApiRaltHrAdmin-package-new')
    }

    return (
        <Layout>
            <AppSider /> {/* Use the Sider component here */}
            <Layout>
                <AppHeader /> {/* Use the Header component here */}
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className='packageAdminContent'
                    >
                        <div className='packageMain'>
                            <div className='pageHeader'>
                                <h3>Packages</h3>
                                <div className='addNewPackage'>
                                    <Button onClick={() => addNew()}>Add New</Button>
                                </div>
                            </div>
                            <div className='packageTableWrapper'>
                                <Table columns={columns} dataSource={packageList} pagination={false}
                                    scroll={{ x: 1000 }} />
                            </div>

                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight:'700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default AdminPackage;
