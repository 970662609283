import React from 'react'
import { Row, Col, Image } from 'antd';
import serviceImage from "../Assets/Images/Service1.png";
// import ReactPlayer from 'react-player/lazy';

const Resume = (props) => {
  // let PropData = props?.data;

  return (
    <div className='coverLetterWrapper' id="Resume">
      <Row className='topSection'>
        <Col md={12} xs={24} className='leftSection'>
          <div>
            <h2 style={{fontWeight:700}}>Resume & Cover Letter</h2>
            <p>
              At RaltHR, we craft personalized resumes and cover letters that
              highlight your unique skills and experiences. Our goal is to create
              documents that not only showcase your strengths but also perfectly
              align with the roles you are seeking.
            </p>
            <p>
              Whether you are starting your career or looking to advance to the next
              level, we are here to assist your job search by providing professional,
              impressive resumes and cover letters.
            </p>
          </div>
        </Col>
        <Col md={12} xs={24} className='RightSection'>
          <div className='imgWrapper'>
            {/* <ReactPlayer url={PropData?.videoUrl} className="videoWrapper" /> */}
             <Image src={serviceImage} alt="Not found" className='img-fluid' preview={false} /> 
          </div>
        </Col>
      </Row>
      <Row className='bottomSection'>
        <div className='listWrapper'>
        <h2 style={{fontWeight:700}}>How it can help you?</h2>
          <Row>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>Expert Writers:</strong> Industry experts customize your resume.</li>
                <li><strong>Phone Consultation:</strong> Discuss your career goals with a professional writer.</li>
                <li><strong>Keyword Optimization:</strong> Ensures your resume passes through ATS filters.</li>
                <li><strong>Final Documents:</strong> Delivered in Word and PDF formats.</li>
              </ul>
            </Col>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>First Draft Delivery:</strong> Within 3-5 business days.</li>
                <li><strong>Customized content:</strong> Personalized to match job applications.</li>
                <li><strong>Professional Tone:</strong> Written by industry experts to highlight your strengths.</li>
                <li><strong>ATS-friendly format:</strong> Designed to increase visibility and findability.</li>
              </ul>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default Resume;