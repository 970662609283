import React from 'react'
import { Row, Col, Image } from 'antd';
import serviceImage from "../Assets/Images/Service2.png";
// import ReactPlayer from 'react-player/lazy';

const LinkedIn = (props) => {
  // let propData = props?.data;

  return (
    <div className='linkedWrapper' id="Profile">
      <Row className='topSection'>
        <Col md={12} xs={24} className=' leftSection'>
          <div className='imgWrapper'>
            {/* <ReactPlayer url={propData?.videoUrl} className="videoWrapper" /> */}
            <Image src={serviceImage} alt="Not found" className='img-fluid' preview={false} /> 
          </div>
        </Col>
        <Col md={12} xs={24} className='RightSection'>
          <div>
          <h2 style={{fontWeight:700}}>LinkedIn, Job Portal & More</h2>
            <p>
              We offer profile optimization services to help you stand out on
              leading job portals like LinkedIn and Job Portal & More. Our
              team tailors your profile to showcase your skills, experience,
              and achievements in the most appealing way.
            </p>
            <p>
              We ensure that your profile is not only keyword-optimized for
              better search visibility but is also designed to attract the right
              opportunities and get your profile noticed by recruiters and
              hiring managers.

            </p>
          </div>
        </Col>

      </Row>
      <Row className='bottomSection'>
        <div className='listWrapper'>
        <h2 style={{fontWeight:700}}>Why is this important to you?</h2>
          <Row>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>Boost Visibility:</strong> Enhances your profile's
                  visibility to recruiters</li>
                <li><strong>Stand Out:</strong> Makes your profile more appealing
                  and professional</li>
                <li><strong>Attract Opportunities: </strong> Increases the chances
                  of receiving job offers.</li>
                <li><strong>Optimize Keywords:</strong> Ensures your profile ranks
                  higher in search results.</li>
              </ul>
            </Col>
            <Col md={12} xs={24}>
              <ul>
                <li><strong>Showcase Skills:</strong> Highlights your skills and
                  achievements effectively</li>
                <li><strong>Professional Image:</strong> Creates a polished and
                  consistent online presence.</li>
                <li><strong>Networking:</strong>  Expand your professional
                  network and connections.
                </li>
                <li><strong>Job Alerts:</strong> Customizes job alerts based on your
                  profile and preferences.</li>
              </ul>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default LinkedIn