import React, { useEffect, useState } from 'react';
import { Layout, Button, Form, Input, Card, Col, Row, message, theme } from 'antd';
import AppHeader from '../Components/Layout/adminHeader';
import AppSider from '../Components/Layout/adminSider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Url } from "../../../Http";

const { Content, Footer } = Layout;

const SendLink = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { id } = useParams(); // Fetch user ID from URL
    const [userDetails, setUserDetails] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(Url + `/api/get-user-by-id/${id}`)
            .then((res) => {
                setUserDetails(res.data?.data);
            })
            .catch((err) => {
                console.error(err);
            })
    }, []);

    // State for the dynamic job list
    const [jobs, setJobs] = useState([{ companyName: '', jobRole: '', location: '', link: '' }]);

    // Function to add a new job entry
    const addJobField = () => {
        setJobs([...jobs, { companyName: '', jobRole: '', location: '', link: '' }]);
    };

    // Function to remove a job entry
    const removeJobField = (index) => {
        const newJobs = [...jobs];
        newJobs.splice(index, 1);
        setJobs(newJobs);
    };

    // Function to handle input changes in job fields
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newJobs = [...jobs];
        newJobs[index][name] = value;
        setJobs(newJobs);
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {
            await axios.post(Url + '/api/send-jobs-email', { userId: id, jobs });
            navigate('/ApiRaltHrAdmin-users');
            message.success('Email sent successfully!');
        } catch (error) {
            message.error('Failed to send email.');
        }
    };

    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content style={{ margin: '0px 16px 0' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className="sendLinkWrapper"
                    >
                        <div className='cardHeader'>
                            <h5><strong>{userDetails?.firstName + ' ' + userDetails?.lastName}</strong></h5>
                            <h5>Plan: <strong>{userDetails?.plan}</strong></h5>
                        </div>
                        <div className='mainBody'>
                            <Form onFinish={handleSubmit} layout="vertical">
                                {jobs.map((job, index) => (
                                    <Card
                                        key={index}
                                        type="inner"
                                        title={`Job ${index + 1}`}
                                        extra={<Button type="danger" onClick={() => removeJobField(index)}>Remove</Button>}
                                        style={{ marginBottom: '16px' }}
                                    >
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item label="Company Name" required>
                                                    <Input
                                                        name="companyName"
                                                        value={job.companyName}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        placeholder="Enter company name"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Job Role" required>
                                                    <Input
                                                        name="jobRole"
                                                        value={job.jobRole}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        placeholder="Enter job role"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item label="Location" required>
                                                    <Input
                                                        name="location"
                                                        value={job.location}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        placeholder="Enter location"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Job Link" required>
                                                    <Input
                                                        name="link"
                                                        value={job.link}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        placeholder="Enter job link"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}

                                <Button type="dashed" onClick={addJobField} block style={{ marginBottom: '16px' }}>
                                    Add Another Job
                                </Button>

                                <div style={{ textAlign: 'right' }} className='submitWrapper'>
                                    <Button type="primary" htmlType="submit">
                                        Send Email
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>RaltHR {new Date().getFullYear()}</Footer>
            </Layout>
        </Layout>
    );
};

export default SendLink;
