    import React, { useState, useEffect } from 'react';
    import AppHeader from '../Layout/adminHeader';
    import AppSider from '../Layout/adminSider';
    import { Layout, Button, Form, Input, Switch, Space, message } from 'antd';
    import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
    import { useNavigate } from 'react-router-dom';
    import axios from 'axios';
    import { useParams } from 'react-router-dom';
    import { Url } from '../../../../Http';
    import Swal from 'sweetalert2';

    const { Content, Footer } = Layout;
    const { TextArea } = Input;

    const NewPackage = () => {
        const navigate = useNavigate();
        const { id } = useParams();
        const [form] = Form.useForm(); // Create a form instance
        const [services, setServices] = useState([]);

        // Fetch service data when component mounts
        useEffect(() => {
            axios.get(Url + `/api/get-package/${id}`)
                .then((res) => {
                    const data = res?.data?.data;
                    setServices(data?.services);
                    if (data) {
                        form.setFieldsValue(data); // Set form fields when data is available
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }, [id, form]);

        const updatePackage = (values) => {
            // Include services in the form values
            const updatedValues = {
                ...values,
                services: services, // Add the services array to the form data
            };

            axios.put(Url + `/api/edit-package/${id}`, updatedValues)
                .then((res) => {
                    Swal.fire({
                        title: 'Package Updated',
                        icon: 'success'
                    }).then(() => {
                        navigate('/ApiRaltHrAdmin-package');
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        };


        const handleServiceToggle = (index, checked) => {
            const updatedServices = [...services];
            updatedServices[index].value = checked;
            setServices(updatedServices);
        };

        const addService = () => {
            setServices([...services, { name: '', value: false }]);
        };

        const removeService = (index) => {
            const updatedServices = services.filter((_, idx) => idx !== index);
            setServices(updatedServices);
        };

        const handleServiceNameChange = (index, e) => {
            const updatedServices = [...services];
            updatedServices[index].name = e.target.value;
            setServices(updatedServices);
        };

        return (
            <Layout>
                <AppSider />
                <Layout>
                    <AppHeader />
                    <Content style={{ margin: '0px 16px 0' }}>
                        <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
                            <div className='serviceMain'>
                                <div className='pageHeader'>
                                    <h3>Edit Package</h3>
                                </div>
                                <Form
                                    name="newForm"
                                    form={form}
                                    onFinish={updatePackage}
                                    encType="multipart/form-data"
                                >
                                    {/* Title */}
                                    <div className='formItemWrapper'>
                                        <label>Title</label>
                                        <Form.Item
                                            name="title"
                                            rules={[{ required: true, message: 'Please enter a title!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    {/* Amount */}
                                    <div className='formItemWrapper'>
                                        <label>Amount</label>
                                        <Form.Item
                                            name="amount"
                                            rules={[{ required: true, message: 'Please enter amount!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    {/* Tagline */}
                                    <div className='formItemWrapper'>
                                        <label>Tagline</label>
                                        <Form.Item
                                            name="tagline"
                                            rules={[{ required: true, message: 'Please enter a tagline!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    {/* Description */}
                                    <div className='formItemWrapper'>
                                        <label>Description</label>
                                        <Form.Item
                                            name="description"
                                            rules={[{ required: true, message: 'Please enter a description!' }]}
                                        >
                                            <TextArea />
                                        </Form.Item>
                                    </div>

                                    {/* Services Section */}
                                    <div className='formItemWrapper'>
                                        <h4>Services Included</h4>
                                        {services.map((service, index) => (
                                            <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item>
                                                    <Input
                                                        placeholder="Service Name"
                                                        value={service.name}
                                                        onChange={(e) => handleServiceNameChange(index, e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        checked={service.value}
                                                        onChange={(checked) => handleServiceToggle(index, checked)}
                                                    />
                                                </Form.Item>
                                                {services.length > 1 && (
                                                    <MinusCircleOutlined
                                                        onClick={() => removeService(index)}
                                                        style={{ fontSize: 20, color: 'red' }}
                                                    />
                                                )}
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={addService} icon={<PlusOutlined />}>
                                                Add Service
                                            </Button>
                                        </Form.Item>
                                    </div>

                                    {/* Phone Consultation */}
                                    <div className='formItemWrapper'>
                                        <label>Phone Consultation</label>
                                        <Form.Item
                                            name="phoneConsultation"
                                            rules={[{ required: true, message: 'Please enter the number of phone consultations!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>

                                    <div className='submitWrapper'>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Content>
                    <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight:'700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
                </Layout>
            </Layout>
        );
    };

    export default NewPackage;
