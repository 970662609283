import React, { useEffect, useState } from 'react';
import AppHeader from '../Components/Layout/adminHeader';
import AppSider from '../Components/Layout/adminSider';
import { Table, Layout, theme, Button } from 'antd';
import axios from 'axios';
import { Url } from '../../../Http';
import { useNavigate } from 'react-router-dom';
const { Content, Footer } = Layout;

const AdminService = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();

    const [userList, setUserList] = useState([]);

    // Fetch services
    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        try {
            const res = await axios.get(Url + '/api/get-all-users');
            setUserList(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const sendlinkSection = (data) => {
        navigate(`/ApiRaltHrAdmin-send-links/${data?._id}`)
    }

    function formatDateToDDMMYYYY(date) {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city),
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            sorter: (a, b) => a.plan.localeCompare(b.plan),
        },
        {
            title: 'Plan Expiry',
            dataIndex: 'plan_expiry_date',
            key: 'plan_expiry_date',
            render: (text) => formatDateToDDMMYYYY(text),
            sorter: (a, b) => new Date(a.plan_expiry_date) - new Date(b.plan_expiry_date), // Sorting by date
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (record) => (
                <>
                    {record === '1' ? <span className='active'>Active</span> : <span className='due'>Payment Due</span>}
                </>
            ),
            filters: [
                { text: 'Active', value: '1' },
                { text: 'Payment Due', value: '0' },
            ],
            onFilter: (value, record) => record.status === value, // Filtering by status
        },
        {
            title: 'Action',
            render: (record) => (
                <Button
                    onClick={() => sendlinkSection(record)}
                    disabled={record.status === '0'} // Disable button if status is '0'
                >
                    Send Links
                </Button>
            ),
        },
    ];


    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className="serviceAdminContent"
                    >
                        <div className="serviceMain">
                            <div className="pageHeader">
                                <h3>Users</h3>
                            </div>
                            <div className="serviceTableWrapper">
                                <Table columns={columns} dataSource={userList} rowKey="_id" scroll={{ x: 1600 }} />
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default AdminService;
