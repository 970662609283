import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Collapse } from 'antd';

const Reviews = () => {
  const items = [
    {
      key: 1,
      label: 'Eligibility for Refunds',
      children: (
        <div>
          <p>Refunds are only applicable under the following circumstances:</p>
          <ul>
            <li><strong>Double Payment:</strong> If a client has accidentally made duplicate payments for the same service.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 2,
      label: 'Non-Refundable Services',
      children: (
        <div>
          <p>Refunds are not available for:</p>
          <ul>
            <li>Services that have been completed and delivered.</li>
            <li>Partially completed services where the work has already commenced.</li>
            <li>Situations where the client changes their mind after the service has begun.</li>
            <li>Missed deadlines or lack of response from the client’s side, resulting in delays or cancellation of service.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 3,
      label: 'Requesting a Refund',
      children: (
        <div>
          <p>To request a refund, please email us at <a href="mailto:help@ralthr.com">help@ralthr.com</a> with the following details:</p>
          <ul>
            <li>Order/Service ID</li>
            <li>Reason for the refund request</li>
            <li>Any relevant documentation (e.g., screenshots, communication history)</li>
          </ul>
        </div>
      ),
    },
    {
      key: 4,
      label: 'Refund Process',
      children: (
        <div>
          <p>Once your refund request is received, our team will review the details and respond within 7 business days. If the refund is approved, it will be processed within 5 to 7 business days. Refunds will be credited to the original method of payment.</p>
        </div>
      ),
    },
    {
      key: 5,
      label: 'Changes to This Policy',
      children: (
        <div>
          <p>RaltHR India reserves the right to modify this refund policy at any time. Any changes will be posted on this page with an updated revision date.</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className='ReviewsWrapper'>
        <div className='headingSection'>
          <h1>Refund Policy</h1>
          <p>At RaltHR, our priority is to provide the highest level of service and satisfaction to our clients. However, we understand that there may be occasions where a refund is necessary. Please carefully read our refund policy below:</p>
        </div>
        <div className='section'>
          <Collapse items={items} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reviews;
