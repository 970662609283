import React from 'react';
import { IoLogOutOutline } from "react-icons/io5";
import { Layout, theme } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const AppHeader = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken(); // Correct way to access theme tokens

  const navigate = useNavigate();

  const logout = () => {
    localStorage?.clear();
    navigate('/');
  }

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <div className='headderWrapper'>
      <div className='signOutWrapper' onClick={() => logout()}>
        Sign Out <IoLogOutOutline />
      </div>
      </div>
      {/* Add anything specific for the header here */}
    </Header>
  );
};

export default AppHeader;
