import React, { useEffect, useState } from 'react';
import AppHeader from '../Components/Layout/adminHeader';
import AppSider from '../Components/Layout/adminSider';
import { Table, Layout, theme } from 'antd';
import axios from 'axios';
import { Url } from '../../../Http';

const { Content, Footer } = Layout;

const AdminService = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [paymentList, setPaymentList] = useState([]);

  // Fetch services
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const res = await axios.get(Url + '/api/get-all-payment-records');
      setPaymentList(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionID',
      key: 'transactionID',
      sorter: (a, b) => a.transactionID.localeCompare(b.transactionID),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmountPaid',
      key: 'totalAmountPaid',
      sorter: (a, b) => a.totalAmountPaid - b.totalAmountPaid, // Assuming amount is a number
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      sorter: (a, b) => a.plan.localeCompare(b.plan),
    },
    {
      title: 'Plan Expiry Date',
      dataIndex: 'plan_expiry_date',
      key: 'plan_expiry_date',
      render: (text) => formatDateToDDMMYYYY(text),
      sorter: (a, b) => new Date(a.plan_expiry_date) - new Date(b.plan_expiry_date),
    },
  ];

  return (
    <Layout>
      <AppSider />
      <Layout>
        <AppHeader />
        <Content
          style={{
            margin: '0px 16px 0',
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            className="serviceAdminContent"
          >
            <div className="serviceMain">
              <div className="pageHeader">
                <h3>Payment</h3>
              </div>
              <div className="serviceTableWrapper">
                <Table
                  columns={columns}
                  dataSource={paymentList}
                  rowKey="_id"
                  scroll={{ x: 1400 }}
                  pagination={{ pageSize: 10 }} // Optional: Set pagination
                />
              </div>
            </div>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontWeight: '700'
          }}
        >
          RaltHR {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminService;
