import React from 'react'
import { Row, Col, Image } from 'antd';
import ContactImage from "../Assets/Images/contact.png";

const Contact = () => {
  return (
    <div className='contactMainWrapper' id="contact">
      <Row>
        <Col md={12} className='leftSection'>
          <div className='header'>
            <h2>Contact</h2>
            <p>We are here to support you throughout your journey, ensuring you receive guidance and assistance every step of the way. Whether you are looking for information about our services, need help with your job search, or are experiencing technical issues, we have streamlined our communications to make it easier for you to contact us.
              <br />
              <br />
              For any inquiries, including general questions about our services, job search assistance, resume writing, reverse recruiting, or technical support, please contact us at <a href="mailto:support@ralthr.com">support@ralthr.com</a> . Our team is dedicated to providing timely and effective support to meet all your needs. Feel free to contact us, and we will be happy to help you!</p>
          </div>
          <div>

            {/* <div className='emailSection'>
              <b>General Inquiries: <a href="mailto:info@ralthr.com">info@ralthr.com</a></b>
              <p>For any general questions or information about our services.</p>
            </div>
            <div className='emailSection'>
              <b>Job Seeker Assistance:  <a href="mailto:help@ralthr.com">help@ralthr.com</a></b>
              <p>If you need help with your job search or have questions about our resume writing or reverse recruiting services.</p>
            </div>
            <div className='emailSection'>
              <b>Technical Support: <a href="mailto: support@ralthr.com"> support@ralthr.com</a></b>
              <p>For any technical issues or problems with our website or communication tools.</p>
            </div> */}
          </div>
        </Col>
        <Col md={12} className='rightSection'>
          <div>
            <Image src={ContactImage} alt="Not Found" className='img-fluid' preview={false} />
          </div>
        </Col>
      </Row>
    </div>

  )
}

export default Contact