import React, { useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Layout, Button, Form, Input, Switch, Space, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Url } from '../../../../Http';

const { Content, Footer } = Layout;
const { TextArea } = Input;

const NewPackage = () => {
    const navigate = useNavigate();
    const [services, setServices] = useState([
        { name: 'Resume Writing', value: true },
        { name: 'ATS Resume', value: true },
        { name: 'Cover Letter', value: true },
        { name: 'Email Writing', value: false },
        { name: 'Job Links', value: true },
        { name: 'LinkedIn Optimization', value: true },
        { name: 'Naukri Optimization', value: true },
        { name: 'Reverse Recruiting Support', value: false },
        { name: 'Job Search Assistance', value: true }
    ]);

    const createNewPackage = async (values) => {
        try {
            // Include services in the form values
            const packageData = { ...values, services };
            await axios.post(`${Url}/api/create-package`, packageData);
            message.success('Package created successfully!');
            navigate('/ApiRaltHrAdmin-package');
        } catch (error) {
            message.error('Failed to create package');
            console.error('Error:', error);
        }
    };

    const handleServiceToggle = (index, checked) => {
        const updatedServices = [...services];
        updatedServices[index].value = checked;
        setServices(updatedServices);
    };

    const addService = () => {
        setServices([...services, { name: '', value: false }]);
    };

    const removeService = (index) => {
        const updatedServices = services.filter((_, idx) => idx !== index);
        setServices(updatedServices);
    };

    const handleServiceNameChange = (index, e) => {
        const updatedServices = [...services];
        updatedServices[index].name = e.target.value;
        setServices(updatedServices);
    };

    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content style={{ margin: '0px 16px 0' }}>
                    <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
                        <div className='serviceMain'>
                            <div className='pageHeader'>
                                <h3>Add New Package</h3>
                            </div>
                            <Form
                                name="newForm"
                                onFinish={createNewPackage}
                                encType="multipart/form-data"
                            >
                                {/* Title */}
                                <div className='formItemWrapper'>
                                    <label>Title</label>
                                    <Form.Item
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter a title!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>

                                {/* Amount */}
                                <div className='formItemWrapper'>
                                    <label>Amount</label>
                                    <Form.Item
                                        name="amount"
                                        rules={[{ required: true, message: 'Please enter amount!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>

                                {/* Tagline */}
                                <div className='formItemWrapper'>
                                    <label>Tagline</label>
                                    <Form.Item
                                        name="tagline"
                                        rules={[{ required: true, message: 'Please enter a tagline!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>

                                {/* Description */}
                                <div className='formItemWrapper'>
                                    <label>Description</label>
                                    <Form.Item
                                        name="description"
                                        rules={[{ required: true, message: 'Please enter a description!' }]}
                                    >
                                        <TextArea />
                                    </Form.Item>
                                </div>

                                {/* Services Section */}
                                <div className='formItemWrapper'>
                                    <h4>Services Included</h4>
                                    {services.map((service, index) => (
                                        <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item>
                                                <Input
                                                    placeholder="Service Name"
                                                    value={service.name}
                                                    onChange={(e) => handleServiceNameChange(index, e)}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                    checked={service.value}
                                                    onChange={(checked) => handleServiceToggle(index, checked)}
                                                />
                                            </Form.Item>
                                            {services.length > 1 && (
                                                <MinusCircleOutlined
                                                    onClick={() => removeService(index)}
                                                    style={{ fontSize: 20, color: 'red' }}
                                                />
                                            )}
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={addService} icon={<PlusOutlined />}>
                                            Add Service
                                        </Button>
                                    </Form.Item>
                                </div>

                                {/* Phone Consultation */}
                                <div className='formItemWrapper'>
                                    <label>Phone Consultation</label>
                                    <Form.Item
                                        name="phoneConsultation"
                                        rules={[{ required: true, message: 'Please enter the number of phone consultations!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>

                                <div className='submitWrapper'>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Submit</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default NewPackage;
