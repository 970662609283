import React from 'react'
import { Row, Col, Image } from 'antd';
import AboutImage from "../Assets/Images/aboutUs.png";

const About = () => {
  return (
    <div className='aboutWrapper' id="about">
      <Row>
        <Col md={12} className='leftSection'>
          <h1>About</h1>
          <p>
            Established in 2012, Ralt Infotech Pvt Ltd is committed to connecting professionals with opportunities that match their skills and passion. Through RaltHR, we provide personalized job search and reverse recruiting services that simplify the career journey.
          </p>
          <br />
          <p>
            Our services include initial consultation to understand your career goals, professionally crafted ATS-friendly resumes and cover letters, and profile optimization for greater visibility on LinkedIn and job portals. We provide 5 curated job recommendations weekly, reverse recruiting where we apply for jobs on your behalf, and constant guidance throughout the process. Updates are regularly sent via WhatsApp and email to keep you informed.
          </p>
          <br />
          <p>
            BanksterIndia, a product of Ralt Infotech, specializes in the BFSI sector, helping companies hire top talent. At RaltHR, our mission is to make your job search stress-free and help you find the right role.  Join us, and together, we can turn your career aspirations into reality.
          </p>
        </Col>
        <Col md={12} className='rightSection'>
          <div className='imgWrapper'>
            <Image src={AboutImage} alt="Not found" className="img-fluid" preview={false} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default About