import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import { Collapse } from 'antd';
const Disclaimer = () => {

  const items = [
    {
      key: 1,
      label: 'General Information',
      children: 'RaltHR India is a platform that assists job seekers by offering resume writing, job search guidance, and reverse recruiting services. While we do our best to support candidates in finding jobs aligned with their skills and passions, we cannot guarantee specific results, including job placements or interviews.'
    },
    {
      key: 2,
      label: 'No Employment Guarantee',
      children: 'Our services aim to enhance your job search experience by providing professional resume writing, job opportunity identification, and application support. However, RaltHR India does not guarantee employment, interview calls, or job offers. Success in securing a job is influenced by many factors beyond our control, including your qualifications, market conditions, employer decisions, and other variables.'
    },
    {
      key: 3,
      label: 'Accuracy of Information',
      children: 'We strive to ensure that the information we provide, including job opportunities, is accurate and up-to-date. However, RaltHR India makes no warranties or representations about the completeness, reliability, or accuracy of the information provided on our website or shared via email, WhatsApp, or other communication channels. Job markets are dynamic, and opportunities may change without notice. We encourage job seekers to independently verify all job listings and conduct due diligence.'
    },
    {
      key: 4,
      label: 'Third-Party Links and Services',
      children: 'Our website and communications may include links to third-party websites or services, such as job portals, employer websites, and external resources. RaltHR India does not control these third-party sites and is not responsible for their content, policies, or practices. Clicking on such links is at your own risk, and we recommend reviewing the terms and privacy policies of any third-party websites.'
    },
    {
      key: 5,
      label: 'Professional Advice Disclaimer',
      children: 'RaltHR India offers career advice, resume writing, and job application guidance based on industry experience and best practices. However, our advice should not be considered as professional or legal advice tailored to your specific situation. We recommend consulting with career counselors, legal advisors, or other professionals for personalized guidance.'
    },
    {
      key: 6,
      label: 'Limitation of Liability',
      children: 'In no event shall RaltHR India, its employees, affiliates, or partners be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website, services, or information provided. This includes, but is not limited to, losses resulting from missed job opportunities, reliance on inaccurate information, or any errors or omissions in the services we provide.'
    },
    {
      key: 7,
      label: 'Use of Services',
      children: 'RaltHR India offers services like reverse recruiting and resume writing with the goal of helping job seekers. However, our services are provided “as is” and “as available,” with no warranties of any kind. We reserve the right to modify or discontinue any aspect of our services without notice.'
    },
    {
      key: 8,
      label: 'Confidentiality and Data Usage',
      children: 'While we take appropriate steps to protect your data and maintain confidentiality, we cannot guarantee complete security of information transmitted over the internet. By using our services, you agree to our data collection and usage practices as outlined in our Privacy Policy.'
    },
    {
      key: 9,
      label: 'Indemnification',
      children: 'You agree to indemnify, defend, and hold harmless RaltHR India, its affiliates, and employees from any claims, damages, losses, liabilities, and expenses arising from your use of our services or any violation of these terms.'
    },
    {
      key: 10,
      label: 'Changes to This Disclaimer',
      children: 'RaltHR India reserves the right to update or modify this disclaimer at any time. Changes will be effective immediately upon posting on our website. We encourage you to periodically review this page to stay informed about any updates.'
    },
    {
      key: 11,
      label: 'Acceptance of This Disclaimer',
      children: 'By using our website and services, you signify your acceptance of this disclaimer. If you do not agree with the terms outlined here, please refrain from using our services.'
    },
  ]
  return (
    <>
      <Header />
      <div className='disclaimerWrapper'>
        <div className='headingSection'>
          <h1>Disclaimer</h1>
          <p>Welcome to RaltHR India. We strive to provide the best possible services to job seekers, including resume writing and reverse recruiting. However, before using our website or services, it’s essential to understand the terms and conditions outlined in this disclaimer.</p>
          <p>By accessing and using this website and our services, you acknowledge and agree to the terms of this disclaimer. If you do not agree with any part of this disclaimer, please do not use our website or services.</p>
        </div>
        <div className='section'>
          <Collapse
            items={items}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Disclaimer