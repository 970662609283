import React, { useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Layout, theme, Input, Button, Form, message } from 'antd';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor CSS
import axios from 'axios';
import { Url } from '../../../../Http';
import { useNavigate } from 'react-router-dom';

const { Content, Footer } = Layout;

const AddTemplate = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();

    const [templateName, setTemplateName] = useState('');
    const [templateContent, setTemplateContent] = useState('');

    const handleEditorChange = (content) => {
        setTemplateContent(content);
    };

    const handleSubmit = async () => {
        if (!templateName || !templateContent) {
            message.error('Both Template Name and Content are required.');
            return;
        }

        try {
            const response = await axios.post(`${Url}/api/add-template`, {
                name: templateName,
                content: templateContent,
            });
            if (response.status === 200) {
                message.success('Template added successfully!');
                navigate('/ApiRaltHrAdmin-email-templates'); // Redirect after success
            }
        } catch (error) {
            message.error('Failed to add template');
        }
    };

    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className="addNewTemplateWrapper"
                    >
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Form.Item label="Template Name" required>
                                <Input
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    placeholder="Enter template name"
                                />
                            </Form.Item>
                            <Form.Item label="Template Content" required>
                                <SunEditor
                                    height="400px"
                                    placeholder="Please type your template content here..."
                                    onChange={handleEditorChange}  // Event handler for content changes
                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo', 'bold', 'italic', 'underline', 'fontColor', 'hiliteColor', 'align', 'list', 'table', 'link', 'image', 'codeView'],
                                        ],
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Add Template
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700',
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default AddTemplate;
