import React, { useEffect, useState } from 'react';
import Banner from '../Components/Banner';
import About from "../Components/About";
import Services from "../Components/Services";
import Pricing from "../Components/Pricing";
import WhyChooseUs from "../Components/WhyChooseUs";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
// import { Url } from '../Http';
// import axios from 'axios';

const Home = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set loading to false after 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    // const registerUser = () => {
    //     let data = {
    //         username: 'RaltGroupAdminHrUser',
    //         password:'P@SSW$$%%O@@R#DR&L*H$',
    //         role:'admin'
    //     }
    //     axios.post(Url + '/api/register', data)
    //     .then((res) => {
    //         console.info("Res", res);
    //     })
    //     .catch((err) => {
    //         console.error(err);
    //     })
    // }
    return (
        <div className='mainSection'>
            {loading ? (
                <div className='loaderWrapperComponent'>
                    {/* Replace with your loader component or style */}
                    <div className='spinner'></div>
                </div>
            ) : (
                <>
                    {/* <button onClick={registerUser}>Test</button> */}
                    <Banner />
                    <About />
                    <Services />
                    <Pricing />
                    <WhyChooseUs />
                    <Contact />
                    <Footer />
                </>
            )}
        </div>
    );
};

export default Home;
