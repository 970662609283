import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Collapse } from 'antd';

const Faq = () => {
  const items = [
    {
      key: 1,
      label: 'What is RaltHR India?',
      children: (
        <div>
          <p><b>RaltHR India</b> is a company dedicated to helping job seekers find roles that match their skills and passions. Founded in 2024, we offer comprehensive services, including professional resume writing and reverse recruiting. Our mission is to make your job search as smooth and successful as possible by providing personalized support and expert guidance.</p>
        </div>
      ),
    },
    {
      key: 2,
      label: 'What is reverse recruiting?',
      children: (
        <div>
          <p>Reverse recruiting is a service where we take on the responsibility of your job search. We start by creating a customized, optimized resume for you. Then, our team actively searches the job market to find openings that match your profile. We share these opportunities with you via WhatsApp and email, and assist you throughout the application process, including interview preparation.</p>
        </div>
      ),
    },
    {
      key: 3,
      label: 'How does the resume writing service work?',
      children: (
        <div>
          <p>Our resume writing service involves several steps:</p>
          <ul>
            <li><b>Consultation:</b>We begin with a consultation to understand your career goals, skills, and experience.</li>
            <li><b>Resume Creation:</b>Our team crafts a professional and impactful resume that highlights your strengths and aligns with industry standards.</li>
            <li><b>Revisions:</b>We offer revisions to ensure the final resume meets your expectations and effectively showcases your qualifications.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 4,
      label: ' What should I expect during the reverse recruiting process?',
      children: (
        <div>
          <p>During the reverse recruiting process, you can expect the following:</p>
          <ul>
            <li><b>Resume Creation:</b>We’ll create a resume tailored to your career goals and industry.</li>
            <li><b>Job Search:</b>We’ll actively look for job openings that match your profile.</li>
            <li><b>Opportunity Sharing:</b>We’ll send you job opportunities via WhatsApp and email.</li>
            <li><b>Application Support:</b>We’ll assist you with applying to jobs and provide guidance throughout the interview process.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 5,
      label: 'How can I contact RaltHR India?',
      children: (
        <div>
          <p>You can reach us via the following emails:</p>
          <ul>
            <li><b>General Inquiries:</b><a href="info@ralthr.com">info@ralthr.com</a></li>
            <li><b>Job Seeker Assistance:</b><a href=" help@ralthr.com"> help@ralthr.com</a></li>
            <li><b>Technical Support:</b><a href="support@ralthr.com">support@ralthr.com</a></li>
          </ul>
          <p>Feel free to contact us for any questions or support related to our services.</p>
        </div>
      ),
    },
    {
      key: 6,
      label: 'How long does the resume writing process take?',
      children: (
        <div>
          <p>The resume writing process typically takes 3-5 business days. This includes time for consultation, resume creation, and revisions. If you need an expedited service, please let us know, and we will do our best to accommodate your request.</p>
        </div>
      ),
    },
    {
      key: 7,
      label: 'How do you find job openings for reverse recruiting?',
      children: (
        <div>
          <p>Our team utilizes various resources, including job boards, company websites, and industry contacts, to find job openings that align with your skills and career goals. We continuously monitor the job market to ensure we provide you with the most relevant and up-to-date opportunities.</p>
        </div>
      ),
    },
    {
      key: 8,
      label: 'What if I am not satisfied with my resume?',
      children: (
        <div>
          <p>We strive to create resumes that meet your expectations and effectively highlight your strengths. If you’re not satisfied with the initial draft, we offer revisions to make necessary changes. Your feedback is crucial, and we work closely with you to ensure the final resume is to your liking.</p>
        </div>
      ),
    },
    {
      key: 9,
      label: 'Can I use the reverse recruiting service if I am not actively looking for a job?',
      children: (
        <div>
          <p>Yes, you can use our reverse recruiting service even if you’re not actively looking for a job. Our service can help you explore new opportunities and prepare for future career moves, whether you’re passively interested in exploring new roles or actively seeking a change.</p>
        </div>
      ),
    },
    {
      key: 10,
      label: 'How do I sign up for RaltHR India’s services?',
      children: (
        <div>
          <p>To sign up for our services, select your plan on our website, fill out the contact form, and complete the payment process. Once you’ve done that, we’ll guide you through scheduling a consultation and discussing your needs.</p>
        </div>
      ),
    },
    {
      key: 11,
      label: 'What types of jobs do you help with?',
      children: (
        <div>
          <p>We assist with job placements across over 55 industries. Whether you’re seeking roles in finance, technology, healthcare, engineering, manufacturing, education, retail, or any other field, our services are tailored to help you find positions that align with your skills and career aspirations.</p>
        </div>
      ),
    },
    {
      key: 12,
      label: 'Do you offer services for international job seekers?',
      children: (
        <div>
          <p>Currently, our primary focus is on job seekers within India. However, if you’re looking for international opportunities, we can provide general guidance and connect you with resources that might be helpful for international job searches.</p>
        </div>
      ),
    },
    {
      key: 13,
      label: 'How often do you update job openings?',
      children: (
        <div>
          <p>We update job openings regularly to ensure you receive the most current and relevant opportunities. Our team continuously monitors the job market and shares new openings with you as they become available.</p>
        </div>
      ),
    },
    {
      key: 14,
      label: 'What if I encounter technical issues with your website or communication tools?',
      children: (
        <div>
          <p>For technical support, please contact us at <a href="mailto:support@ralthr.com">support@ralthr.com</a>. Our technical team will assist you with any issues related to our website or communication tools to ensure a smooth experience.</p>
        </div>
      ),
    },
    {
      key: 15,
      label: 'Can I get a refund if I am not satisfied with the services?',
      children: (
        <div>
          <p>We aim to provide high-quality services and work closely with you to ensure satisfaction. If you’re not satisfied with our services, please reach out to us to discuss your concerns. We are committed to addressing any issues and finding a resolution.</p>
        </div>
      ),
    },
    {
      key: 16,
      label: 'How do you protect my personal information?',
      children: (
        <div>
          <p>At RaltHR India, we take your privacy seriously. We use secure systems and follow strict protocols to protect your personal information. Your data is only used for the purposes of providing our services and will not be shared with third parties without your consent.</p>
        </div>
      ),
    },
    {
      key: 17,
      label: 'Can I update my resume after receiving the final version?',
      children: (
        <div>
          <p>Yes, you can request updates to your resume after receiving the final version. We offer revision services to ensure your resume remains current and accurately reflects your skills and experience.</p>
        </div>
      ),
    },
    {
      key: 18,
      label: 'What should I do if I don’t receive job opportunities in my field?',
      children: (
        <div>
          <p>If you don’t receive job opportunities in your field, please let us know. We can adjust our search parameters and focus on finding openings that better match your industry and career goals.</p>
        </div>
      ),
    },
    {
      key: 19,
      label: 'How can I provide feedback on your services?',
      children: (
        <div>
          <p>We welcome and encourage feedback from our clients. You can provide feedback by contacting us via email at help@jobassistindia.com. Your input helps us improve our services and better assist future clients.</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className='faqWrapper'>
        <div className='headingSection'>
          <h1>FAQs</h1>
          <p>Welcome to the RaltHR India FAQ page! Here, you’ll find answers to some of the most common questions about our services. If you have any other questions or need further assistance, please don’t hesitate to reach out to us.</p>
        </div>
        <div className='section'>
          <Collapse items={items} />
        </div>
        <div className='bottomSection'>
          <p>We hope these FAQs have answered your questions about RaltHR India and our services. If you need further assistance, don’t hesitate to get in touch with us. We’re here to help you achieve your career goals!</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
