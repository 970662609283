const items = [
    {
      key: 1,
      label: 'Information We Collect',
      children: (
        <div>
          <p>We collect various types of information to provide and improve our services:</p>
          <ul>
            <li><strong>Personal Information:</strong> This includes your name, contact details (email address, phone number), and other personal data you provide when you register for our services or contact us.</li>
            <li><strong>Resume and Job Search Data:</strong> We collect information related to your resume, job preferences, and career goals. This may include your work history, education, skills, and any other details you provide to us.</li>
            <li><strong>Communication Data:</strong> Any correspondence or communication you have with us, including emails, chat messages, and feedback, is collected to better understand your needs and respond to your inquiries.</li>
            <li><strong>Usage Data:</strong> We collect information about how you interact with our website and services, such as IP addresses, browser types, pages visited, and time spent on our site.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 2,
      label: 'How We Use Your Information',
      children: (
        <div>
          <p>We use the information we collect to:</p>
          <ul>
            <li><strong>Provide and Manage Services:</strong>To deliver the services you request, including resume writing and reverse recruiting. This includes tailoring job recommendations based on your profile and assisting with the application process.</li>
            <li><strong>Improve Our Services:</strong>To analyze usage patterns and improve our website and services. Your feedback helps us enhance our offerings and ensure a better user experience.</li>
            <li><strong>Communicate with You:</strong> To send you important updates, notifications, and information about your application or job search. We may also use your contact information to respond to your inquiries and provide customer support.</li>
            <li><strong>Compliance and Legal Requirements: </strong> To comply with legal obligations and enforce our terms of service. We may use your information to address legal disputes, prevent fraud, and ensure the safety and security of our users.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 3,
      label: 'How We Protect Your Information',
      children: (
        <div>
          <p>We implement various security measures to safeguard your personal information, including:</p>
          <ul>
            <li><strong>Encryption:</strong>We use encryption technologies to protect your data during transmission and storage.</li>
            <li><strong>Access Controls:</strong> Access to your personal information is restricted to authorized personnel who need it to perform their job duties.</li>
            <li><strong>Secure Systems:</strong> We maintain secure servers and employ industry-standard security practices to prevent unauthorized access, data breaches, and other threats.</li>
          </ul>
          <p>Despite our efforts to protect your data, no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee absolute security, but we are committed to taking reasonable measures to protect your information.</p>
        </div>
      ),
    },
    {
      key: 4,
      label: 'Sharing Your Information',
      children: (
        <div>
          <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
          <ul>
            <li><strong>Service Providers:</strong>We may share your data with third-party service providers who assist us in delivering our services, such as IT support, payment processing, or email communication. These providers are obligated to protect your information and use it only for the purposes for which it was shared.</li>
            <li><strong>Legal Requirements:</strong>We may disclose your information if required by law or to comply with legal processes, such as responding to a subpoena or court order.</li>
            <li><strong>Business Transfers:</strong>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity. We will notify you of any such change and ensure that your data remains protected.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 5,
      label: 'Cookies and Tracking Technologies',
      children: (
        <div>
          <p>We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files placed on your device that help us remember your preferences, track usage patterns, and improve our services. You can control cookie settings through your browser, but disabling cookies may affect your ability to use certain features of our website.</p>
        </div>
      ),
    },
    {
      key: 6,
      label: 'Your Rights and Choices',
      children: (
        <div>
          <p>You have the following rights regarding your personal information:</p>
          <ul>
            <li><strong>Access and Correction:</strong>You may request access to your personal data and request corrections if it is inaccurate or incomplete.</li>
            <li><strong>Opt-Out:</strong>You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the emails.</li>
            <li><strong>Deletion:</strong>You may request the deletion of your personal information, subject to legal and contractual obligations. Please note that some data may be retained for record-keeping purposes or to fulfill legal requirements..</li>
          </ul>
          <p>To exercise any of these rights, please contact us using the contact information provided below.</p>
        </div>
      ),
    },
    {
      key: 7,
      label: 'Children’s Privacy',
      children: (
        <div>
          <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected information from a child, we will take steps to delete it.</p>
        </div>
      ),
    },
    {
      key: 8,
      label: 'Changes to This Privacy Policy',
      children: (
        <div>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will post the updated policy on our website and notify you of any significant changes. Your continued use of our services after the changes constitutes your acceptance of the revised policy.</p>
        </div>
      ),
    },
  ];

  export default items;