import React, { useEffect, useState } from 'react'
import { Row, Col, Image, Card } from 'antd';
import axios from 'axios';
import { Url, ImageUrl } from '../Http';
import Resume from './Resume';
import LinkedIn from './LinkedIN';
import Reverse from './ReverseRecruiting';

const Services = () => {
  const [serviceList, setServiceList] = useState([]);
  const [resumeUrl, setResumeUrl] = useState();
  const [profileOptimizeUrl, setProfileOptimizeUrl] = useState();
  const [reverseUrl, setReverseUrl] = useState();

  useEffect(() => {
    axios.get(Url + '/api/get-services')
      .then((res) => {
        console.warn("Response", res);
        setServiceList(res?.data);
        let resumeVideoUrl = res?.data[0];
        let profileOPtimizeUrl = res?.data[1];
        let reverseUrl = res?.data[2];
        setResumeUrl(resumeVideoUrl);
        setProfileOptimizeUrl(profileOPtimizeUrl);
        setReverseUrl(reverseUrl);
      })
      .catch((err) => {
        console.error(err);
      })
  }, []);

  const breakTitle = (data) => {
    let newData = data?.split(" ");
    window.location.href = `#${newData[0]}`;
  }

  return (
    <>
      <div className='serviceWrapper' id="services">
        <h1>What We Do?</h1>
        <Row className='mainRow'>
          {serviceList?.map((item) => (
            <Col md={8} xs={24} key={item?.key}>
              <Card onClick={() => breakTitle(item.title)} style={{ cursor: 'pointer' }}>
                <div className='imageWrapper'>
                  <Image src={ImageUrl + item?.image} alt="Not found" className='img-fluid' preview={false} />
                </div>
                <div className='textSection'>
                  <h2>{item?.title}</h2>
                  <p>{item?.description}</p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Resume data={resumeUrl} />

      <LinkedIn data={profileOptimizeUrl} />

      <Reverse data={reverseUrl} />
    </>
  )
}

export default Services