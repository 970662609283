import React, { useState, useEffect } from 'react';
import { Row, Col, Drawer, Button } from 'antd';
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import { MenuOutlined } from '@ant-design/icons';

const Header = () => {
  const [activeSection, setActiveSection] = useState('banner'); // Default active section
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Check if it's mobile

  // Monitor window size to toggle mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bannerHeight = document.getElementById('banner')?.offsetHeight || 0;
      const aboutTop = document.getElementById('about')?.offsetTop || 0;
      const servicesTop = document.getElementById('services')?.offsetTop || 0;
      const pricingTop = document.getElementById('pricing')?.offsetTop || 0;
      const whyUsTop = document.getElementById('why-us')?.offsetTop || 0;
      const contactTop = document.getElementById('contact')?.offsetTop || 0;

      // Add some buffer to avoid overlapping sections
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      console.info("test", scrollPosition >= bannerHeight - 50)
      if (scrollPosition >= contactTop - 50) {
        setActiveSection('contact');
      } else if (scrollPosition >= whyUsTop - 50) {
        setActiveSection('why-us');
      } else if (scrollPosition >= pricingTop - 50) {
        setActiveSection('pricing');
      } else if (scrollPosition >= servicesTop - 50) {
        setActiveSection('services');
      } else if (scrollPosition >= aboutTop - 50) {
        setActiveSection('about');
      } else if (scrollPosition >= bannerHeight - 50) {
        setActiveSection('home');
      } else {
        setActiveSection('banner');
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check active section on load
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Open Drawer
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  // Close Drawer
  const onClose = () => {
    setDrawerVisible(false);
  };

  const renderMenuItems = () => (
    <ul>
      <li className={activeSection === 'banner' ? 'active' : ''}>
        <a href="#banner" onClick={onClose}>Home</a>
      </li>
      <li className={activeSection === 'about' ? 'active' : ''}>
        <a href="#about" onClick={onClose}>About</a>
      </li>
      <li className={activeSection === 'services' ? 'active' : ''}>
        <a href="#services" onClick={onClose}>What We Do</a>
      </li>
      <li className={activeSection === 'pricing' ? 'active' : ''}>
        <a href="#pricing" onClick={onClose}>Pricing</a>
      </li>
      <li className={activeSection === 'why-us' ? 'active' : ''}>
        <a href="#why-us" onClick={onClose}>Why Us</a>
      </li>
      <li className={activeSection === 'contact' ? 'active' : ''}>
        <a href="#contact" onClick={onClose}>Contact</a>
      </li>
    </ul>
  );

  return (
    <div className={`headerWrapper ${activeSection !== 'banner' ? 'fixed' : ''}`}>
      <Row justify="space-between" align="middle">
        <Col xs={16} md={4} className='imageColumn'>
          <div onClick={() => window.location.href = "/"} style={{ cursor: 'Pointer' }}>
            <h3>RaltHR</h3>
            <span>An Outplacement Agency</span>
          </div>
        </Col>

        {isMobile ? (
          <>
            <Col xs={8} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                className='drawerBtn'
                style={{ background: '#1e293b' }}
              />
            </Col>
            <Drawer
              title="Menu"
              placement="right"
              onClose={onClose}
              visible={drawerVisible}
            >
              {renderMenuItems()}
            </Drawer>
          </>
        ) : (
          <>
            <Col md={14} className='listColumn'>
              <div>{renderMenuItems()}</div>
            </Col>
            <Col md={6} className='linkColumn'>
              <div>
                <a href="https://www.instagram.com/ralthrinsta/" target='_blank' rel='noreferrer'><FaInstagram /></a>
                <a href="https://www.facebook.com/ralthr" target='_blank' rel='noreferrer'><FaFacebook /></a>
                <a href="https://www.linkedin.com/company/ralt-hr/" target='_blank' rel='noreferrer'><FaLinkedin /></a>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Header;
