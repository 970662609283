import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Tag } from 'antd';
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCircleCheck } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Url } from "../Http";

const Pricing = () => {
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = () => {
    axios.get(Url + '/api/get-packages')
      .then((res) => {
        setPackageData(res?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const renderService = (service) => (
    service === 'Yes' ? <FaCircleCheck style={{ color: 'green' }} /> : service === 'No' ? <AiFillCloseCircle style={{ color: 'red' }} /> : service
  );

  const detailFormPage = (id) => {
    navigate(`/checkout-page/${id}`);
  }

  return (
    <div className='pricingWrapper' id="pricing">
      <div className='mainHeader pb-4'>
        <h2>Our Pricing Plans</h2>
      </div>
      <Row gutter={[16, 16]}>
        {packageData.map(item => (
          <Col xs={24} sm={12} md={6} key={item._id} className='cardContainerCOl'>
            <Card
              className={item.title === 'Premium' ? 'pricingCard premiumCard' : 'pricingCard'}
              title={
                <div className='cardHeaderWrapper'>
                  {/* Conditional rendering for "Recommended" tag */}
                  {item.title === 'Premium' && (
                    <Tag color="gold" className='recommendedTag'>Recommended</Tag>
                  )}
                  <div className='label'>{item.title}</div>
                  <div className='price'><b>₹{item.amount}/ Month</b></div>
                  <div className='desc'><p>{item?.description}</p></div>
                </div>
              }
              bordered={false}
              headStyle={{ fontSize: '22px', fontWeight: '500' }}
              actions={[
                <Button className='buyNowBtn' onClick={() => detailFormPage(item._id)}>Get Started</Button>,
              ]}
            >
              {/* Display Yes/No services */}
              {item.services.map(service => (
                <Row className='wrapper' key={service._id}>
                  <Col md={2} className='mr-4'>
                    {renderService(service.value ? 'Yes' : 'No')}
                  </Col>
                  <Col md={20}>
                    <p>{service.name}</p>
                  </Col>
                </Row>
              ))}

              {/* Phone Consultation displayed separately */}
              <Row className='wrapper phoneConsultationRow' style={{ marginTop: '16px', padding: '10px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}>
                <Col md={2}>
                  <FaPhoneAlt style={{ fontSize: '16px', color: '#1890ff' }} />
                </Col>
                <Col md={20}>
                  <p><strong>{item.phoneConsultation}</strong> Phone Consultation</p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Pricing;
