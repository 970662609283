import React, { useEffect, useState } from 'react'
import Header from './Header';
import { Row, Col, Image } from 'antd';
import axios from 'axios';
import { Url } from '../Http.js';

const Banner = () => {
  const [bannerData, setBannerData] = useState();

  useEffect(() => {
    axios.get(Url + '/api/landing-page')
      .then((res) => {
        console.info("Respo", res);
        setBannerData(res?.data[0]);
      })
      .catch((err) => {
        console.error(err);
      })
  }, []);

  return (
    <>
      <div className='bannerWrapper' id="banner">
        <Header />
        <Row className='mainBannerRow'>
          <Col md={12} className='leftSection'>
            <h1>
              {bannerData?.title}
              {/* Do You Need Help Finding a Job? */}
            </h1>
            <p>
              {bannerData?.description}
              {/* You are in the right place. We help professionals find the right job based on their skills. */}
            </p>
            {/* <Button className="startBtn">START NOW</Button> */}
          </Col>
          <Col md={12} className='rightSection'>
            <div className='imgWrapper'>
              <Image src={bannerData?.image} alt="Not found" className="img-fluid" preview={false} />
              {/* <ReactPlayer url={bannerData?.videoUrl} width={500} height={300} /> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Banner