import React, { useState, useEffect } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Layout, theme, Input, Button, Form, message } from 'antd';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor CSS
import axios from 'axios';
import { Url } from '../../../../Http';
import { useNavigate, useParams } from 'react-router-dom';

const { Content, Footer } = Layout;

const EditTemplate = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateName, setTemplateName] = useState('');
  const [templateContent, setTemplateContent] = useState('');

  useEffect(() => {
    fetchTemplateDetails();
  }, []);

  const fetchTemplateDetails = async () => {
    try {
      const res = await axios.get(`${Url}/api/get-template-by-id/${id}`);
      const { name, content } = res.data.data;
      setTemplateName(name);
      setTemplateContent(content);
    } catch (err) {
      console.error(err);
      message.error('Failed to fetch template details.');
    }
  };

  const handleEditorChange = (content) => {
    setTemplateContent(content);
  };

  const handleSubmit = async () => {
    if (!templateName || !templateContent) {
      message.error('Both Template Name and Content are required.');
      return;
    }

    try {
      const response = await axios.put(`${Url}/api/update-template/${id}`, {
        name: templateName,
        content: templateContent,
      });

      if (response.status === 200) {
        message.success('Template updated successfully!');
        navigate('/ApiRaltHrAdmin-email-templates'); // Redirect after success
      }
    } catch (error) {
      message.error('Failed to update template');
    }
  };

  return (
    <Layout>
      <AppSider />
      <Layout>
        <AppHeader />
        <Content
          style={{
            margin: '0px 16px 0',
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            className="addNewTemplateWrapper"
          >
            <div className='pageHeader'>
              <h3>Edit Template</h3>
            </div>
            <Form layout="vertical" onFinish={handleSubmit}>
              <div className='formItemWrapper'>
                <label>Template Name</label>
                <Form.Item required>
                  <Input
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    placeholder="Enter template name"
                  />
                </Form.Item>
              </div>
              <div className='formItemWrapper'>
                <label>Template Content</label>
                <Form.Item required>
                  <SunEditor
                  height='300'
                    placeholder="Please type your template content here..."
                    setContents={templateContent}  // Preload editor content with fetched data
                    onChange={handleEditorChange}  // Event handler for content changes
                    setOptions={{
                      buttonList: [
                        ['undo', 'redo', 'bold', 'italic', 'underline', 'fontColor', 'hiliteColor', 'align', 'list', 'table', 'link', 'image', 'codeView'],
                      ],
                      defaultTag: 'table',
                      pasteStyles: true,
                    }}

                  />
                </Form.Item>
              </div>
              <div className='submitWrapper'>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update Template
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontWeight: '700',
          }}
        >
          RaltHR {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default EditTemplate;
