import React, { useEffect, useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Space, Table, Layout, theme, Button, Image, message, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Url, ImageUrl } from '../../../../Http';
import Swal from 'sweetalert2';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

const { Content, Footer } = Layout;

const AdminService = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState([]);

  // Fetch services
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const res = await axios.get(Url + '/api/get-services');
      setServiceList(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Handle service deletion
  const deleteService = async (id) => {
    try {
      const res = await Swal.fire({
        title: 'Are you sure you want to delete this service?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'No, keep it'
      });

      if (res.isConfirmed) {
        await axios.delete(`${Url}/api/delete-service/${id}`);
        message.success('Service deleted successfully');
        fetchServices(); // Refresh the list after deletion
      }
    } catch (err) {
      console.error('Failed to delete service:', err);
      message.error('Failed to delete service');
    }
  };

  const editService = (id) => {
    navigate(`/ApiRaltHrAdmin-service-edit/${id}`);
  };

  // Truncate long description text
  const truncateText = (text, maxLength = 50) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <Tooltip title={text.length > 50 ? text : ''}>
          {truncateText(text, 50)}
        </Tooltip>
      ),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (record) => (
        <div className="recordImage">
          <Image src={ImageUrl + record} alt="Not found" className="img-fluid" width={100} height={80} />
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editService(record._id)}><CiEdit />Edit</Button>
          <Button onClick={() => deleteService(record._id)} danger><RiDeleteBin6Line />Delete</Button>
        </Space>
      ),
    },
  ];

  const addNew = () => {
    navigate('/ApiRaltHrAdmin-service-new');
  };

  return (
    <Layout>
      <AppSider />
      <Layout>
        <AppHeader />
        <Content
          style={{
            margin: '0px 16px 0',
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            className="serviceAdminContent"
          >
            <div className="serviceMain">
              <div className="pageHeader">
                <h3>Services</h3>
                <div className="addNewService">
                  <Button onClick={addNew}>Add New</Button>
                </div>
              </div>
              <div className="serviceTableWrapper">
                <Table
                  columns={columns}
                  dataSource={serviceList}
                  rowKey="_id"
                  pagination={false}
                  scroll={{ x: 1000 }}  // Horizontal scroll
                />
              </div>
            </div>
          </div>
        </Content>
        <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight:'700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminService;
