import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd'; // Using Ant Design for a modern look
import { FrownOutlined } from '@ant-design/icons'; // Icon for a visual touch

const PaymentFailure = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        navigate('/'); // Navigate back to checkout page
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
            <Result
                status="error"
                icon={<FrownOutlined style={{ color: '#ff4d4f', fontSize: '48px' }} />}
                title="Payment Failed"
                subTitle="There was an issue processing your payment. Please try again."
                extra={
                    <Button type="primary" onClick={handleRetry} size="large">
                        Go Back
                    </Button>
                }
                style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}
            />
        </div>
    );
};

export default PaymentFailure;
