import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Collapse } from 'antd';

const TermsAndConditions = () => {
  const items = [
    {
      key: 1,
      label: 'Definitions',
      children: (
        <div>
          <p>Refunds are only applicable under the following circumstances:</p>
          <ul>
            <li>“Company,” “We,” “Our,” or “Us” refers to RaltHR.</li>
            <li>Service” refers to the offerings provided by RaltHR, including but not limited to resume writing, reverse recruiting, and job search assistance.</li>
            <li>“User,” “You,” or “Your” refers to any person who visits, uses, or engages with our website or services.</li>
            <li>“Client” refers to any individual or entity that avails of our paid services.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 2,
      label: 'Service Description',
      children: (
        <div>
          <p>RaltHR offers career-related services, primarily focused on job seekers looking for roles that align with their skills and passion. Our services include:</p>
          <ul>
            <li><b>Resume Writing:</b>Professional creation and optimization of resumes tailored to industry standards.</li>
            <li><b>Reverse Recruiting:</b>We actively search for job openings that match your profile, share them with you, and guide you through the application process.</li>
            <li><b>Job Search Assistance:</b>We support candidates throughout the job application and interview journey.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 3,
      label: 'Eligibility',
      children: (
        <div>
          <p>To use our services, you must be at least 18 years old and legally capable of entering into binding contracts. By using our services, you confirm that you meet these requirements.</p>
        </div>
      ),
    },
    {
      key: 4,
      label: 'Service Agreement and Payment',
      children: (
        <div>
          <p>By purchasing our services, you agree to the following:</p>
          <ul>
            <li>Payments must be made in full before service delivery unless otherwise agreed upon.</li>
            <li>All fees are non-refundable once the service has commenced, except in cases of significant service failure on our part.</li>
            <li>We reserve the right to adjust pricing, payment terms, and services at our discretion.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 5,
      label: 'Reverse Recruiting Service',
      children: (
        <div>
          <p>Our reverse recruiting service is designed to help you find job opportunities tailored to your skills and passion. However, we do not guarantee employment or specific outcomes. The service includes:</p>
          <ul>
            <li>Resume optimization.</li>
            <li>Searching for and identifying job openings.</li>
            <li>Sharing job application links via WhatsApp and email.</li>
            <li>Assisting with the application process and providing interview tips.</li>
          </ul>
          <p>Please Note:</p>
          <ul>
            <li>We do not apply on your behalf unless explicitly authorized by you.</li>
            <li>The final responsibility for submitting applications and attending interviews lies with you.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 6,
      label: 'Privacy Policy',
      children: (
        <div>
          <p>We respect your privacy and are committed to protecting your personal information. Please refer to our [Privacy Policy] for details on how we collect, use, and protect your data.</p>
        </div>
      ),
    },
    {
      key: 7,
      label: 'User Account and Security',
      children: (
        <div>
          <p>When you create an account with us, you are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. We are not liable for any loss or damage arising from your failure to protect your account information.</p>
        </div>
      ),
    },
    {
      key: 8,
      label: 'Intellectual Property Rights',
      children: (
        <div>
          <p>All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of RaltHR or its licensors and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or use any content from our website without our prior written consent.</p>
        </div>
      ),
    },
    {
      key: 9,
      label: 'Limitation of Liability',
      children: (
        <div>
          <p>While we strive to provide the best possible service, RaltHR will not be liable for:</p>
          <ul>
            <li>Any indirect, incidental, or consequential damages resulting from the use of our services.</li>
            <li>Any loss of data, job opportunities, or employment prospects.</li>
            <li>Delays, interruptions, or failures beyond our control, including technical issues and third-party service failures.</li>
          </ul>
        </div>
      ),
    },
    {
      key: 10,
      label: 'Disclaimer',
      children: (
        <div>
          <p>RaltHR provides its services on an “as-is” and “as-available” basis. We do not guarantee that our services will be error-free, uninterrupted, or meet your expectations. While we make every effort to help you find relevant job opportunities, we do not guarantee job placement.</p>
        </div>
      ),
    },
    {
      key: 11,
      label: 'Termination and Suspension',
      children: (
        <div>
          <p>We reserve the right to terminate or suspend your access to our services at any time, without notice, if you violate these Terms and Conditions or engage in behavior detrimental to our business or other users.</p>
        </div>
      ),
    },
    {
      key: 12,
      label: 'Amendments to Terms and Conditions',
      children: (
        <div>
          <p>We may update these Terms and Conditions from time to time to reflect changes in our services or legal obligations. Any updates will be posted on our website, and continued use of our services constitutes acceptance of the updated terms.</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className='TermsAndConditionsWrapper'>
        <div className='headingSection'>
          <h1>Terms and Conditions</h1>
          <p>Welcome to RaltHR! These Terms and Conditions outline the rules and regulations for the use of our website and services. By using our website and any of our services, you agree to comply with these terms. If you do not agree with any of these terms, please do not use our website or services.</p>
        </div>
        <div className='section'>
          <Collapse items={items} />
        </div>
        <div className='bottomSection'>
          <p>Thank you for choosing RaltHR! We look forward to helping you achieve your career goals.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
