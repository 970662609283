import React, { useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Layout, Button, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Url } from "../../../../Http";
import { useNavigate } from 'react-router-dom';

const { Content, Footer } = Layout;
const { TextArea } = Input;

const NewService = () => {
    const [file, setFile] = useState(null); // Store the uploaded file
    const navigate = useNavigate();

    const createNewService = async (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('videoUrl', values.videoUrl);
        if (file) {
            formData.append('image', file);  // Add image to formData
        }

        try {
            // Sending the form data to the backend API
            await axios.post(Url + '/api/services', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            message.success('Service created successfully!');
            navigate('/ApiRaltHrAdmin-service');
        } catch (error) {
            console.error("Error creating service", error);
            message.error('Failed to create service!');
        }
    };

    const handleFileChange = (info) => {
        setFile(info.file);
        message.success(`${info.file.name} file uploaded successfully`);
    };

    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content style={{ margin: '0px 16px 0' }}>
                    <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
                        <div className='serviceMain'>
                            <div className='pageHeader'>
                                <h3>Add New Service</h3>
                            </div>
                            <Form
                                name="newForm"
                                onFinish={createNewService}
                                encType="multipart/form-data" // Required for file upload
                            >
                                <div className='formItemWrapper'>
                                    <label>Title</label>
                                    <Form.Item
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter a title!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Description</label>
                                    <Form.Item
                                        name="description"
                                        rules={[{ required: true, message: 'Please enter description!' }]}
                                    >
                                        <TextArea />
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Image</label>
                                    <Form.Item>
                                        <Upload
                                            name="image"
                                            listType="picture"
                                            showUploadList={false}
                                            beforeUpload={() => false} // Prevent automatic upload
                                            onChange={handleFileChange}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Video Url</label>
                                    <Form.Item
                                        name="videoUrl"
                                        rules={[{ required: true, message: 'Please enter video URL!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className='submitWrapper'>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Submit</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default NewService;
