import React, { useEffect, useState } from 'react';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { Layout, theme, Button, Form, Input, Upload, message, Image } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Url } from '../../../../Http';
import { useForm } from 'antd/es/form/Form';

const { Content, Footer } = Layout;

const Landing = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [landingPageData, setLandingPageData] = useState();
    const [form] = useForm();
    const [file, setFile] = useState(null); // Store the uploaded file

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const res = await axios.get(Url + '/api/landing-page');
            const data = res?.data[0];
            setLandingPageData(data);
            if (data) {
                form.setFieldsValue({
                    title: data.title,
                    description: data.description,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const UpdateLandingPage = async (values) => {
        const formData = new FormData();

        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('videoUrl', values.videoUrl);

        // Extract just the filename from the image URL if no new file is uploaded
        const originalFileName = landingPageData?.image?.split('/').pop();  // Get the filename from the URL
        formData.append('originalFile', originalFileName);  // Only append the filename

        if (file !== null) {
            formData.append('image', file);  // Add new image to formData if a file is selected
        }

        try {
            // Sending the form data to the backend API
            await axios.post(Url + '/api/landing-page', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            message.success('Banner Updated!');
            fetchData();
        } catch (error) {

            message.error('Failed to update landing page!');
        }
    };


    const handleFileChange = (info) => {
        setFile(info.file);
        message.success(`${info.file.name} file uploaded successfully`);
    };


    return (
        <Layout>
            <AppSider />
            <Layout>
                <AppHeader />
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className='landingPageMain'
                    >
                        <div className='packageMain'>
                            <div className='pageHeader'>
                                <h3>Landing Page</h3>
                            </div>
                            <Form
                                name="newForm"
                                onFinish={UpdateLandingPage}
                                form={form}
                                encType="multipart/form-data">
                                <div className='formItemWrapper'>
                                    <label>Page Title</label>
                                    <Form.Item
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter a title!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Page Description</label>
                                    <Form.Item
                                        name="description"
                                        rules={[{ required: true, message: 'Please enter description!' }]}
                                    >
                                        <TextArea />
                                    </Form.Item>
                                </div>
                                <div className='imagePreview'>
                                    <Image src={landingPageData?.image} alt="Not Found" className="img-fluid" width={400} height={300} />
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Upload Image</label>
                                    <Form.Item
                                    >
                                        <Upload
                                            name="image"
                                            listType="picture"
                                            showUploadList={false}
                                            beforeUpload={() => false} // Prevent automatic upload
                                            onChange={handleFileChange}
                                        >
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='submitWrapper'>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Save</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight:'700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default Landing;
