import React from 'react';
import { Navigate } from 'react-router-dom';

const checkUserRole = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;

    // Decode JWT token here (you can use libraries like jwt-decode)
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Extract payloads
    return decodedToken?.role;  // Assuming role is stored in token payload
};

const ProtectedRoute = ({ element, requiredRole }) => {
    const userRole = checkUserRole();

    if (userRole === requiredRole) {
        return element;
    } else {
        // If the user is not authenticated or doesn't have the required role, redirect to the home page or login page
        return <Navigate to="/" />;
    }
};

export default ProtectedRoute;
