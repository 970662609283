import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Collapse } from 'antd';
import items from '../Helper/privacyComponents';

const Privacy = () => {
  return (
    <>
      <Header />
      <div className='privacyWrapper'>
        <div className='headingSection'>
          <h1>Privacy Policy</h1>
          <p>Welcome to RaltHR. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our services, including resume writing and reverse recruiting. By using our services, you agree to the terms outlined in this policy.</p>
        </div>
        <div className='section'>
          <Collapse items={items} />
        </div>
        <div className='bottomSection'>
          <p>
            Thank you for choosing RaltHR. We are committed to protecting your privacy and ensuring a positive experience with our services.
          </p>
          <p><b>Last Updated:</b>23/08/2024</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
