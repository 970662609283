import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Statistic, Table, Radio, Button } from 'antd';
import { UserOutlined, ShoppingCartOutlined, DollarOutlined, TeamOutlined } from '@ant-design/icons';
import AppHeader from './Components/Layout/adminHeader';
import AppSider from './Components/Layout/adminSider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { Url } from "../../Http";
import { useNavigate } from 'react-router-dom';

const { Content, Footer } = Layout;

const App = () => {
  const [timePeriod, setTimePeriod] = useState('daily');
  const [paymentData, setPaymentData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(Url + '/api/get-all-payment-records')
      .then((res) => {
        setPaymentData(res?.data);
        // Calculate the total amount paid
        const totalAmount = res?.data.reduce((sum, payment) => sum + payment.totalAmountPaid, 0);
        setTotalAmountPaid(totalAmount);
        axios.get(Url + '/api/get-all-payment-records')
          .then((res) => {
            setUserList(res?.data);
          })
          .catch((err) => {
            console.error(err);
          })
      })
      .catch((err) => {
        console.error(err);
      })
  }, []);

  const Items = [
    {
      key: 1,
      icon: <UserOutlined style={{ fontSize: '32px', color: '#3b82f6' }} />,
      label: <Statistic title="New Users" value={userList?.filter(user => new Date(user.date).toLocaleDateString() === new Date().toLocaleDateString()).length} />,
      backgroundColor: '#f0f9ff',
    },
    {
      key: 2,
      icon: <DollarOutlined style={{ fontSize: '32px', color: '#f59e0b' }} />,
      label: <Statistic title="Total Amount" value={totalAmountPaid} prefix="₹" />,
      backgroundColor: '#fff7ed',
    },
    {
      key: 3,
      icon: <TeamOutlined style={{ fontSize: '32px', color: '#10b981' }} />,
      label: <Statistic title="Total Users" value={userList?.length} />,
      backgroundColor: '#ecfdf5',
    },
    {
      key: 4,
      icon: <ShoppingCartOutlined style={{ fontSize: '32px', color: '#ef4444' }} />,
      label: <Statistic title="Total Sales" value={paymentData?.length} />,
      backgroundColor: '#fef2f2',
    },
  ];

  // Helper function to format date
  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`; // "YYYY-MM-DD"
  };

  // Define chart data based on the selected time period
  const userChartData = {
    daily: paymentData.reduce((acc, payment) => {
      const date = formatDate(payment.date);
      if (!acc[date]) acc[date] = 0;
      acc[date] += payment.totalAmountPaid;
      return acc;
    }, {}),

    monthly: paymentData.reduce((acc, payment) => {
      const date = new Date(payment.date);
      const month = `${date.getFullYear()}-${date.getMonth() + 1}`; // "YYYY-MM"
      if (!acc[month]) acc[month] = 0;
      acc[month] += payment.totalAmountPaid;
      return acc;
    }, {}),

    yearly: paymentData.reduce((acc, payment) => {
      const year = new Date(payment.date).getFullYear(); // "YYYY"
      if (!acc[year]) acc[year] = 0;
      acc[year] += payment.totalAmountPaid;
      return acc;
    }, {}),
  };

  // Prepare data for chart
  const chartData = userChartData[timePeriod];

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Payments Overview',
    },
    xAxis: {
      categories: Object.keys(chartData), // Get the categories from the chart data
    },
    yAxis: {
      title: {
        text: 'Total Amount Paid',
      },
    },
    series: [
      {
        name: 'Total Amount',
        data: Object.values(chartData), // Get the values from the chart data
      },
    ],
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmountPaid',
      key: 'totalAmountPaid',
    },
  ];

  const viewMorePayment = () => {
    navigate('/ApiRaltHrAdmin-payment')
  }

  return (
    <Layout>
      <AppSider />
      <Layout>
        <AppHeader />
        <Content
          style={{
            margin: '0px 16px 0',
          }}
        >
          <div className="adminHomeMain">
            <div className="widgetWrapper ">
              <Row gutter={[16, 16]}>
                {Items?.map((item) => (
                  <Col md={6} xs={24} key={item.key}>
                    <Card
                      style={{
                        backgroundColor: item.backgroundColor,
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      }}
                      hoverable
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.icon}
                        <div style={{ marginLeft: '16px' }}>
                          {item.label}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>

            {/* Filter Radio Buttons */}
            <Row justify="center" style={{ marginBottom: '20px' }} className='chartRow'>
              <Col md={24} xs={24}>
                <Card>
                  <div className='chartHeader'>
                    <div>
                      <h4>User's Traffic</h4>
                    </div>
                    <div>
                      <Radio.Group
                        value={timePeriod}
                        onChange={(e) => setTimePeriod(e.target.value)}
                      >
                        <Radio.Button value="daily">Daily</Radio.Button>
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="yearly">Yearly</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>

                  <div
                    style={{
                      borderRadius: '10px',
                      padding: '20px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className="tableRow">
              <Card>
                <h4>Recent Payments</h4>
                <Table columns={columns} dataSource={paymentData} rowKey="_id" pagination={false} />
                <div className='viewMoreBtn'>
                  <Button onClick={viewMorePayment}>View More</Button>
                </div>
              </Card>
            </Row>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontWeight: '700'
          }}
        >
          RaltHR {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
