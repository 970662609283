import React, { useEffect, useState } from 'react';
import { Layout, theme, Form, Input, Button, Upload, message, Image } from 'antd';
import AppHeader from '../Layout/adminHeader';
import AppSider from '../Layout/adminSider';
import { UploadOutlined } from '@ant-design/icons';
import { ImageUrl, Url } from '../../../../Http';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const { Content, Footer } = Layout;
const { TextArea } = Input;

const EditService = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [file, setFile] = useState(null); // Store the uploaded file
    const [serviceData, setServiceData] = useState(null); // Store the service data
    const { id } = useParams();
    const [form] = Form.useForm(); // Create a form instance
    const navigate = useNavigate();

    // Fetch service data when component mounts
    useEffect(() => {
        axios.get(Url + `/api/get-service/${id}`)
            .then((res) => {
                const data = res?.data?.data;
                setServiceData(data); // Store the service data
                if (data) {
                    form.setFieldsValue(data); // Set form fields when data is available
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [id, form]);

    const editService = async (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);
        if (file) {
            formData.append('image', file); // Add image to formData
        }

        axios.put(Url + `/api/edit-service/${id}`, formData)
            .then((res) => {
                Swal.fire({
                    title: 'Service Updated',
                    icon: 'success'
                }).then((res) => {
                    navigate('/ApiRaltHrAdmin-service');
                })
            })
            .catch((err) => {
                console.error(err);
            })
    };

    const handleFileChange = (info) => {
        setFile(info.file);
        message.success(`${info.file.name} file uploaded successfully`);
    };

    return (
        <Layout>
            <AppSider /> {/* Use the Sider component here */}
            <Layout>
                <AppHeader /> {/* Use the Header component here */}
                <Content
                    style={{
                        margin: '0px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                        className='serviceMain'
                    >
                        <div className='pageHeader'>
                            <h3>Edit Service</h3>
                        </div>
                        <div className='formWrapper'>
                            <Form
                                form={form} // Bind the form instance
                                name="editForm"
                                onFinish={editService}
                                encType="multipart/form-data" // Required for file upload
                                initialValues={serviceData} // Optional, for the initial render
                            >
                                <div className='formItemWrapper'>
                                    <label>Title</label>
                                    <Form.Item
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter a title!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Description</label>
                                    <Form.Item
                                        name="description"
                                        rules={[{ required: true, message: 'Please enter description!' }]}
                                    >
                                        <TextArea />
                                    </Form.Item>
                                </div>
                                <div className='formItemWrapper'>
                                    <label>Image</label>
                                    <Image src={ImageUrl + serviceData?.image} alt="Not found" className="img-fluid" width={250} height={200} />
                                    <Form.Item>
                                        <Upload
                                            name="image"
                                            listType="picture"
                                            showUploadList={false}
                                            beforeUpload={() => false} // Prevent automatic upload
                                            onChange={handleFileChange}
                                            maxCount={1}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='submitWrapper'>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Submit</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                        fontWeight: '700'
                    }}
                >
                    RaltHR {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default EditService;
