import React, { useEffect, useState } from 'react';
import { Row, Form, Input, Button, Spin, Col, Select, message, Modal, Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Url } from "../../src/Http";
// import { HiOutlineShoppingBag } from "react-icons/hi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import privacyItems from "../Helper/privacyComponents";
import Swal from 'sweetalert2';
import Footer from './Footer';

const { Option } = Select;

const DetailForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm(); // Create form instance
    const [loading, setLoading] = useState(true);
    const [payLoading, setPayLoading] = useState(false); // For the loader on Pay Now button
    const [packageData, setPackageData] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Tax and total calculations
    const calculateTaxAndTotal = () => {
        const amount = parseFloat(packageData.amount) || 0; // Use 0 if amount is undefined
        const tax = amount * 0.18; // 18% tax
        const totalAmount = amount + tax; // Total amount
        return { tax, totalAmount };
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top
    }, []);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    useEffect(() => {
        setLoading(true);
        axios.get(Url + `/api/get-package/${id}`)
            .then((res) => {
                form.setFieldsValue({
                    plan: res?.data?.data?.title, // Setting the plan field to the package title
                    planAmount: res?.data?.data?.amount
                });
                setLoading(false);
                setPackageData(res?.data?.data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false); // Ensure loading is false in case of error
            });
    }, [id, form]);

    const submitForm = (values) => {
        setPayLoading(true);
        const data = {
            name: values.firstName + " " + values.lastName,
            first_name: values.firstName,
            last_name: values?.lastName,
            mobileNumber: values.mobile,
            amount: totalAmount,
            pinCode: values?.pinCode,
            city: values?.city,
            state: values?.state,
            address: values?.address,
            country: values?.country,
            email: values?.email,
            plan: packageData?.title
        };

        axios.post(Url + '/api/create-order', data)
            .then((res) => {
                if (res?.status === 204) {
                    Swal.fire({
                        title: 'Looks like you are already registered with us!',
                        text: 'If you need to change your plan, plase contact the admin',
                        icon: 'info'
                    })
                    setPayLoading(false);
                } else {
                    const { paymentId, url } = res.data; // Assume you get paymentId from the backend
                    localStorage.setItem('paymentId', paymentId); // Store paymentId
                    window.location.href = url; // Redirect to the payment URL
                    setPayLoading(false);
                }

            })
            .catch((error) => {
                console.error(error);
                setPayLoading(false);
                message.error('Payment processing failed. Please try again.');
            });
    };


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const paymentId = query.get('id');

        if (paymentId) {
            const redirectUrl = `${Url}/api/status?id=${paymentId}`; // Construct the URL with ID

            axios.get(redirectUrl)
                .then((response) => {

                    const { success, message: msg, redirectUrl } = response.data;

                    if (success) {
                        message.success(msg);
                        navigate(redirectUrl); // Redirect to the success page
                    }
                    // else {
                    //     message.error(msg);
                    //     navigate('/payment-failure'); // Redirect to the failure page
                    // }
                })
                .catch((error) => {
                    console.error('Error checking payment status:', error);
                    message.error('Error checking payment status.');
                    navigate('/'); // Redirect back to checkout in case of error
                });
        }
    }, []);

    const handleOnFailed = (err) => {
        console.error(err);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { tax, totalAmount } = calculateTaxAndTotal();

    return (
        <>
            <div className='checkoutMain'>
                <div className='headerSection'>
                    <div>
                        <span>Checkout</span>
                    </div>
                    <div>

                    </div>
                </div>
                {/* Accordion placed below header */}
                <div className={`accordion ${isAccordionOpen ? 'open' : ''}`}>
                    <div className='accordion-header' onClick={toggleAccordion}>
                        <div className='iconWrapper'>
                            <h5>Show order summary</h5><span className="icon">{isAccordionOpen ? <FaAngleUp /> : <FaAngleDown />}</span>
                        </div>
                        <h4>₹{totalAmount}</h4>
                    </div>
                    {isAccordionOpen && (
                        <div className='accordion-body'>
                            <div className='packageSection'>
                                <div className='packageDetails'>
                                    <div className='title'>
                                        <h5>Package Name:</h5>
                                        <h5 className='value'>{packageData?.title}</h5>
                                    </div>
                                    <div className='amountWrapper'>
                                        <div className='subtotal'>
                                            <h6>Subtotal:</h6>
                                            <h6 className='value'>₹{parseInt(packageData?.amount).toFixed(2)}</h6>
                                        </div>
                                        <div className='price'>
                                            <h6>Estimated Tax (18%):</h6>
                                            <h6 className='value'>₹{tax.toFixed(2)}</h6>
                                        </div>
                                        <div className='total'>
                                            <h5>Total:</h5>
                                            <h5 className='value'><span>INR</span>₹{totalAmount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='checkoutWrapper'>
                    <div className='loaderWrapper'>
                        {loading && <Spin />}
                    </div>
                    <Row>
                        <Col md={12} xs={24} className='leftSection'>
                            <Form form={form}
                                onFinish={submitForm}
                                onFinishFailed={handleOnFailed} >
                                <div className='formItemWrapper'>
                                    <label>Contact</label>
                                    <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
                                        <Input placeholder='Enter your email address' />
                                    </Form.Item>
                                </div>
                                <div className='formHeading'>
                                    <label>Basic Details</label>
                                </div>
                                <div className='formItemWrapper'>
                                    <Form.Item
                                        name="country"
                                        rules={[{ required: true, message: 'Please select your country!' }]}
                                    >
                                        <Select placeholder="Country/Origin">
                                            <Option value="India">India</Option>
                                        </Select>
                                    </Form.Item>
                                </div>

                                <Row className='splitRow'>
                                    <Col xs={24} md={11}>
                                        <div className='formItemWrapper'>
                                            <Form.Item name="firstName" rules={[{ required: true, message: 'Please enter your first name!' }]}>
                                                <Input placeholder='First name' />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <div className='formItemWrapper'>
                                            <Form.Item name="lastName" rules={[{ required: true, message: 'Please enter your last name!' }]}>
                                                <Input placeholder='Last name' />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>

                                <div className='formItemWrapper'>
                                    <Form.Item name="mobile" rules={[{ required: true, message: 'Please enter your address!' }]}>
                                        <Input placeholder='Mobile No.' />
                                    </Form.Item>
                                </div>

                                <div className='formItemWrapper'>
                                    <Form.Item name="address" rules={[{ required: true, message: 'Please enter your address!' }]}>
                                        <Input placeholder='Address' />
                                    </Form.Item>
                                </div>

                                <Row className='threeSPlitRow'>
                                    <Col md={7} xs={24}>
                                        <div className='formItemWrapper'>
                                            <Form.Item name="state" rules={[{ required: true, message: 'Please enter your state!' }]}>
                                                <Input placeholder='State' />
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    <Col md={7} xs={24}>
                                        <div className='formItemWrapper'>
                                            <Form.Item name="city" rules={[{ required: true, message: 'Please enter your city!' }]}>
                                                <Input placeholder='City' />
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    <Col md={7} xs={24}>
                                        <div className='formItemWrapper'>
                                            <Form.Item name="pinCode" rules={[{ required: true, message: 'Please enter your pin code!' }]}>
                                                <Input placeholder='Pin code' />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>

                                <div className='formHeading'>
                                    <label>Payment</label>
                                    <span>All transactions are secure and encrypted.</span>
                                </div>
                                <div className='paymentWrapper'>
                                    <Button type="primary" htmlType="submit" loading={payLoading}>
                                        {payLoading ? 'Processing...' : 'Pay Now'}
                                    </Button>
                                </div>

                                <div className='privacySection'>
                                    <label onClick={showModal} style={{ cursor: 'pointer' }}>Privacy Policy</label>
                                </div>
                            </Form>

                        </Col>
                        <Col md={12} xs={24} className='rightSection'>
                            <div className='packageSection'>
                                <div className='packageHeader'>
                                    <h3>Order Summary</h3>
                                </div>
                                <div className='packageDetails'>
                                    <div className='title'>
                                        <h5>Package Name:</h5>
                                        <h5 className='value'>{packageData?.title}</h5>
                                    </div>
                                    <div className='amountWrapper'>
                                        {/* <div className='price'>
                                            <h6>Price:</h6>
                                            <h6 className='value'>₹{packageData?.amount}</h6>
                                        </div> */}
                                        <div className='subtotal'>
                                            <h6>Subtotal:</h6>
                                            <h6 className='value'>₹{parseInt(packageData?.amount).toFixed(2)}</h6>
                                        </div>
                                        <div className='price'>
                                            <h6>Estimated Tax (18%):</h6>
                                            <h6 className='value'>₹{tax.toFixed(2)}</h6>
                                        </div>
                                        <div className='total'>
                                            <h5>Total:</h5>
                                            <h5 className='value'><span>INR</span>₹{totalAmount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>

            <Modal title="Privacy Policy" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className='privacyModel'>
                <Collapse items={privacyItems} />
                <div>
                    <p>
                        Thank you for choosing RaltHR. We are committed to protecting your privacy and ensuring a positive experience with our services.
                    </p>
                    <p><b>Last Updated:</b> 23/08/2024</p>
                </div>
            </Modal>
        </>
    );
}

export default DetailForm;
