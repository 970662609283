import React from 'react'
import { Row, Col, Card, Image } from 'antd';
import ImageOne from "../Assets/Images/whyOne.png";
import ImageTwo from "../Assets/Images/whyTwo.png";
import ImageThree from "../Assets/Images/whyThree.png";

const WhyChooseUs = () => {

  const items = [
    {
      key: 1,
      icon: ImageOne,
      title: 'Passionate',
      desc: 'We’re dedicated to aligning your skills with your dream job, ensuring every opportunity matches your aspirations.'
    },
    {
      key: 2,
      icon: ImageTwo,
      title: 'Professional',
      desc: 'Our expert team delivers tailored job placements with precision, drawing on extensive industry experience.'
    },
    {
      key: 3,
      icon: ImageThree,
      title: 'Support',
      desc: 'From application to interview, we offer continuous support to navigate your job search seamlessly.'
    },

  ]
  return (
    <div className='whychooseWrapper' id="why-us">
      <div className='mainHeader'>
        <h2>Why Choose Us</h2>
      </div>
      <Row>
        {items?.map((items) => (
          <Col xs={24} md={8}>
            <Card>
              <Image src={items?.icon} preview={false} alt="Not found" />
              <h2>{items?.title}</h2>
              <p>{items?.desc}</p>
            </Card>
          </Col>
        ))}

      </Row>
    </div>
  )
}

export default WhyChooseUs